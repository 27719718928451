html, body {
  height: 100%; }

body {
  font-family: 'Quattrocento Sans', sans-serif;
  color: $grey-dark;
  display: flex;
  flex-direction: column;
  overflow-y: scroll; }

.container {
  @include desktop {
    width: 100%; }
  @include touch {
    margin: 0 $space-l; } }

.content {
  flex: 1; }

.section {
  padding: $space-l 0; }
