.footer {
  color: $grey-light;
  background: $white-bis;
  margin-top: $space-xl;
  padding: $space-l 0;
  border-top: 2px solid $white-ter;
  a {
    color: $grey-light; } }

.footer-columns {
  align-items: center; }

.footer-brand {
  text-transform: uppercase;
  letter-spacing: 2px;
  a {
    text-decoration: none; } }

.footer-social-media-list {
  @include tablet {
    text-align: center; } }

.footer-social-media-list-item {
  display: inline-block;
  margin-right: $space-m;
  width: 24px;
  height: 24px;
  svg {
    fill: $grey-light; } }

.developed-by {
  font-size: 0.8rem;
  padding-top: $space-m;
  border-top: 2px solid $white-ter;
  color: $grey-light;
  margin-bottom: -$space-s;
  @include tablet {
    text-align: center; } }

.footer-languages {
  margin: $space-stack-s; }

.footer-languages, .footer-currencies {
  font-size: 0.8rem;
  @include tablet {
    text-align: right; }
  a {
    text-decoration: none;
    margin-right: $space-xs;
    position: relative;
    &:last-child {
      margin-right: 0; }
    &.is-active {
      font-weight: 700;
      &:after {
        content: '';
        position: absolute;
        left: 1px;
        right: 0;
        bottom: -2px;
        height: 1px;
        background: $grey-lighter; } } } }
