.product-information {
  margin: $space-stack-m;
  font-size: 1.2rem; }

.product-title {
  text-align: center;
  margin: $space-stack-xl;
  h1 {
    font-size: 1.8rem;
    font-weight: 700;
    position: relative;
    display: inline-block;
    &:after {
      content: '';
      position: absolute;
      left: 1px;
      right: 0;
      bottom: -16px;
      height: 2px;
      background: $white-ter; } } }

.product-description {
  line-height: 1.6rem;
  &.has-price {
    margin: $space-stack-m; }
  a {
    display: inline-block;
    text-decoration: none;
    color: $grey-dark;
    background: $white-bis;
    padding: $space-xs $space-s;
    border-bottom: 3px solid $white-ter;
    margin: 0 $space-s $space-s 0; } }

.product-meta-description {
  line-height: 1.5rem;
  padding: $space-m;
  background: $white-ter;
  border-top: 2px solid $grey-lightest;
  font-size: 1rem;
  h3 {
    margin: $space-stack-s;
    font-weight: 400; } }

.product-price {
  display: inline-block;
  padding: $space-m 0;
  font-weight: 700;
  color: $grey-dark;
  margin: $space-stack-l;
  position: relative;
  font-size: 1.4rem; }

.product-images {
  flex-wrap: wrap;
  align-content: start; }

.product-image {
  display: inline-block; }

.product-image-img {
  display: block;
  width: 100%;
  height: auto; }
