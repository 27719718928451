.video-modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
  cursor: pointer; }

.video-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.video-close {
  position: absolute;
  right: $space-l;
  top: $space-l;
  width: $space-l;
  height: $space-l; }

.video-close:before, .video-close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: $white-ter; }

.video-close:before {
  transform: rotate(45deg); }

.video-close:after {
  transform: rotate(-45deg); }
