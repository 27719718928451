.navbar {
  padding: $space-l 0;
  border-bottom: 2px solid $white-ter; }

.navbar--stack {
  margin: $space-stack-l; }

.navbar-item-link {
  color: $grey-dark;
  text-decoration: none;
  margin-right: $space-s;
  text-decoration: none;
  position: relative;
  &:last-child {
    margin-right: 0; }
  &.is-active {
    font-weight: 700;
    h1 {
      font-weight: 700; }
    &:after {
      content: '';
      position: absolute;
      left: 1px;
      right: 0;
      bottom: -4px;
      height: 2px;
      background: $grey-lighter; } } }

.navbar-brand {
  text-transform: uppercase;
  letter-spacing: 2px;
  a {
    color: $grey-dark;
    text-decoration: none;
    &:hover {
      color: $black; } }
  @include tablet {
    text-align: center; } }

.navbar-start {
  position: absolute;
  left: 0;
  bottom: 50%;
  transform: translateY(50%);
  display: none;
  @include tablet {
    display: block; } }

.navbar-end {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translateY(50%); }

.navbar-page {
  .mobile-toggle {
    display: none; }
  @include mobile {
    .mobile-toggle {
      display: block; }
    .navbar-page-items {
      display: none;
      position: absolute;
      bottom: -50px;
      right: 0;
      &.is-active {
        display: block; } } } }

.navbar-category {
  margin: 0 auto;
  max-width: 720px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  .navbar-item {
    display: flex;
    justify-content: center;
    @include tablet {
      width: 120px;
      margin-right: $space-l;
      &:last-child {
        margin-right: 0; } } }
  .navbar-item-link {
    font-size: 1.2rem;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center; }
  .navbar-item-link-text {
    display: inline-block;
    margin: $space-stack-l;
    text-decoration: none; }
  .navbar-item-link-image {
    display: block; }
  &.hide-images {
    .navbar-item-link-text {
      margin: 0; }
    .navbar-item-link-image {
      display: none; } } }
