.category-title {
  text-align: center;
  margin: $space-stack-l;
  h1 {
    font-size: 1.8rem;
    font-weight: 700;
    position: relative;
    display: inline-block;
    &:after {
      content: '';
      position: absolute;
      left: 1px;
      right: 0;
      bottom: -8px;
      height: 2px;
      background: $white-ter; } } }

.category-products {
  flex-wrap: wrap; }

.category-products-item {
  display: inline-block;
  text-decoration: none;
  margin: $space-stack-s;
  text-align: center;
  .category-products-item-image-container {
    display: inline-block;
    position: relative;
    margin: 0 auto $space-m auto; }
  .category-products-item-image-price-tag {
    position: absolute;
    bottom: 0;
    left: 1px;
    background: white;
    padding: $space-inset-xs;
    color: $grey;
    font-size: 0.8rem; }
  img {
    display: block;
    border: 3px solid white;
    box-shadow: 0px 1px 0.1px rgba(0,0,0,0.19); }

  .category-products-item-title {
    h2 {
      color: $grey-dark;
      text-decoration: none;
      text-align: center;
      padding: 0 $space-xs;
      line-height: 1.3rem; } } }
