$space-inset-default: 16px 16px 16px 16px;
$space-inset-xs: 4px 4px 4px 4px;
$space-inset-s: 8px 8px 8px 8px;
$space-inset-m: 16px 16px 16px 16px;
$space-inset-l: 32px 32px 32px 32px;
$space-inset-xl: 64px 64px 64px 64px;
$space-stack-default: 0 0 16px 0;
$space-stack-xs: 0 0 4px 0;
$space-stack-s: 0 0 8px 0;
$space-stack-m: 0 0 16px 0;
$space-stack-l: 0 0 32px 0;
$space-stack-xl: 0 0 64px 0;
$space-default: 16px;
$space-xs: 4px;
$space-s: 8px;
$space-m: 16px;
$space-l: 32px;
$space-xl: 64px;

$grey: hsl(0, 0%, 50%);
$grey-lightest: hsl(0, 0%, 91%);
