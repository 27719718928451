.page-content {
  margin: $space-stack-l;
  h2, h3 {
    font-weight: bold;
    margin-bottom: $space-m; }
  h2 {
    font-size: 1.6rem; }
  h3 {
    font-size: 1.4rem; }
  h4 {
    font-size: 1.3rem;
    margin-bottom: $space-s; }
  p {
    line-height: 1.6rem;
    font-size: 1.2rem; }
  p + h2, h3, h4 {
    padding-top: $space-s; }
  a {
    display: inline-block;
    text-decoration: none;
    color: $grey-dark;
    background: $white-bis;
    padding: $space-xs $space-s;
    border-bottom: 3px solid $white-ter;
    margin: 0 $space-s $space-s 0; } }

.page-sub-content {
  margin-top: $space-l;
  padding-top: $space-l;
  border-top: 2px solid $white-ter;
  p {
    line-height: 1.6rem;
    font-size: 1.2rem; }
  a {
    display: inline-block;
    text-decoration: none;
    color: $grey-dark;
    background: $white-bis;
    padding: $space-xs $space-s;
    border-bottom: 3px solid $white-ter;
    margin: 0 $space-s $space-s 0; } }

.page-title {
  text-align: center;
  margin: $space-stack-xl;
  h1 {
    font-size: 1.8rem;
    font-weight: 700;
    position: relative;
    display: inline-block;
    &:after {
      content: '';
      position: absolute;
      left: 1px;
      right: 0;
      bottom: -16px;
      height: 2px;
      background: $white-ter; } } }

div p {
  margin: $space-stack-m;
  &:last-child {
    margin: 0; } }

.is-center {
  text-align: center; }

.is-hidden {
  display: none; }

.is-grey {
  color: $grey; }

@include mobile {
  .is-hidden-mobile {
    display: none !important; } }

.fa-lg {
  line-height: 1.3333rem; }
